import React from 'react';
import Layout from '../layouts/application';
import Paper from '@mui/material/Paper';
import ReportStatus from '../../components/progress_report/report_status';
import ReportIcon from '../../components/progress_report/icon';

const Container = (props) => {
  if (props.lead.progress_reports.length == 0) {
    return null;
  }

  return (
    <Paper
      elevation={3}
      className={`p-4 m-3 leads-reports ${props.lead.progress_report_status_for_plan} d-flex`}
      style={{ width: 398, minHeight: 261, display: 'flex' }}
    >
      <div className="d-flex flex-column justify-content-between w-100">
        <div>
          <h5 className="lead-name mb-3">{props.lead.full_name}</h5>
          {props.lead.progress_reports.map((report) => (
            <div className="d-flex justify-content-between mb-2" key={report.id}>
              <div className="d-flex">
                <strong className="me-2" style={{ fontSize: 15, lineHeight: 1 }}>
                  {report.initiative.numbering}
                </strong>
                <div style={{ fontSize: 15, lineHeight: 1 }}>{report.initiative.name}</div>
              </div>
              <div style={{ width: 105 }} className="d-flex justify-content-between">
                <div className="me-2">
                  <ReportIcon status={report.status} timeliness={report.timeliness} role="reviewer" showTooltip />
                </div>
                <ReportStatus date={report.due_date} status={report.status} overdue={report.overdue} />
              </div>
            </div>
          ))}
        </div>
        <div className="w-100 d-flex justify-content-between align-items-center mt-3">
          <div>
            {props.facilitator && (
              <div>
                <strong className="reviewer-title">Reviewer:</strong> {props.lead.reviewer.full_name}
              </div>
            )}
          </div>
          <a className="btn btn-att-gray-medium" href={props.lead.show_path}>
            Review
          </a>
        </div>
      </div>
    </Paper>
  );
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <Container {...props}></Container>
    </Layout>
  );
};
