import { getHeaders } from '../headers';

export const createApi = (defaultBaseUrl = '/progress_reports') => {
  return {
    delete: async (id) => {
      const response = await fetch(`${defaultBaseUrl}/${id}`, {
        method: 'DELETE',
        headers: getHeaders(),
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    },
  };
};
