import RailsResource from './rails_resource';
import { schema } from '@rest-hooks/normalizr';

export default class ProgressMonitoringCycleResource extends RailsResource {
  id = undefined;
  initiative_lead = [];
  initiatives = [];
  progress_report_due_date = [];

  pk() {
    return this.id;
  }

  /* eslint-disable */
  static bulkCreate = () => {
    const endpoint = this.create();
    return endpoint.extend({
      url(sp) {
        return `/progress_monitoring_cycles`;
      },
      fetch(bulkParams) {
        return endpoint.fetch.call(this, bulkParams);
      },
      schema: new schema.Array(this),
    });
  };
  /* eslint-enable */

  static urlRoot = '/progress_monitoring_cycles/';
}
