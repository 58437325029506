import React, { useState } from 'react';
import {
  Paper,
  Stack,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
} from '@mui/material';
import NewCyclesForm from '../../components/progress_monitoring_cycle/new_cycles_form';
import Cycle from '../../components/progress_monitoring_cycle/cycle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Layout from '../layouts/application';
const Container = (props) => {
  const [selectedLeadId, setSelectedLeadId] = useState('all');
  const [cycles, setCycles] = useState(props.cycles);
  const [unmonitoredInitiatives, setUnmonitoredInitiatives] = useState(props.unmonitored_initiatives);
  const [orderBy, setOrderBy] = useState('progress_report_due_date');
  const [order, setOrder] = useState('asc');

  const replaceCycles = (cycles) => {
    setCycles(cycles);
    const monitoredInitiativeIds = cycles
      .map((c) => c.monitored_initiatives)
      .flat()
      .map((i) => i.id);
    const newUnmonitoredInitiatives = unmonitoredInitiatives.filter((initiative) => {
      return !monitoredInitiativeIds.includes(initiative.id);
    });
    setUnmonitoredInitiatives(newUnmonitoredInitiatives);
  };

  const replaceCycle = (cycle) => {
    const replaceIndex = cycles.findIndex((c) => c.id === cycle.id);
    const newCycles = [...cycles];
    newCycles.splice(replaceIndex, 1, cycle);
    setCycles(newCycles);
  };

  const deleteCycle = (cycle) => {
    const removeIndex = cycles.findIndex((c) => c.id === cycle.id);
    const newCycles = [...cycles];
    newCycles.splice(removeIndex, 1);
    setCycles(newCycles);
  };

  const sortByDueDate = (a, b) => new Date(a.progress_report_due_date) - new Date(b.progress_report_due_date);
  const sortByFullName = (a, b, property) => a[property].full_name?.localeCompare(b[property].full_name);

  const sortCycles = (cycles, property, newOrder) => {
    if (property === 'progress_report_due_date' && order == 'asc') {
      return cycles.sort(sortByDueDate);
    } else if (property === 'progress_report_due_date' && order == 'desc') {
      return cycles.sort((a, b) => sortByDueDate(b, a));
    } else if (['reporter', 'reviewer'].includes(property) && order == 'asc') {
      return cycles.sort((a, b) => sortByFullName(a, b, property));
    } else if (['reporter', 'reviewer'].includes(property) && order == 'desc') {
      return cycles.sort((a, b) => sortByFullName(b, a, property));
    }
  };

  const selectedLeadsCycles = sortCycles(
    cycles.filter((cycle) => selectedLeadId === 'all' || cycle.reporter.id === selectedLeadId),
    orderBy,
    order
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const renderTable = () => (
    <Table>
      <TableHead>
        <TableCell onClick={() => {}}>
          <TableSortLabel active={orderBy === 'reporter'} direction={orderBy === 'reporter' ? order : 'asc'} onClick={createSortHandler('reporter')}>
            Lead
          </TableSortLabel>
        </TableCell>
        <TableCell onClick={() => {}}>
          <TableSortLabel active={orderBy === 'reviewer'} direction={orderBy === 'reviewer' ? order : 'asc'} onClick={createSortHandler('reviewer')}>
            Reviewer
          </TableSortLabel>
        </TableCell>
        <TableCell onClick={() => {}}>Initiatives</TableCell>
        <TableCell onClick={() => {}}>
          <TableSortLabel
            active={orderBy === 'progress_report_due_date'}
            direction={orderBy === 'progress_report_due_date' ? order : 'asc'}
            onClick={createSortHandler('progress_report_due_date')}
          >
            Due
          </TableSortLabel>
        </TableCell>
        <TableCell>Edit</TableCell>
      </TableHead>
      {selectedLeadsCycles.map((cycle) => (
        <Cycle key={cycle.id} cycle={cycle} replaceCycle={replaceCycle} deleteCycle={deleteCycle} {...props} />
      ))}
    </Table>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Paper className="p-3">
          <NewCyclesForm {...props} replaceCycles={replaceCycles} />
        </Paper>
      </Grid>
      <Grid item xs={9}>
        <Paper className="p-3">
          <Stack direction="row" justifyContent="space-between">
            <h4 className="blue-header">Current Cycles</h4>
            <FormControl>
              <Select value={selectedLeadId} onChange={(e) => setSelectedLeadId(e.target.value)} sx={{ width: 200 }}>
                <MenuItem value={'all'}>All Leads</MenuItem>
                {props.leads.map((lead) => (
                  <MenuItem key={lead.id} value={lead.id}>
                    {lead.full_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          {selectedLeadsCycles.length > 0 ? renderTable() : <Typography>There are no cycles for the selected lead.</Typography>}
        </Paper>
        <Accordion className="mt-3" defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <h4 className="blue-header">Unmonitored Initiatives</h4>
          </AccordionSummary>
          <AccordionDetails>
            <Table>
              <TableHead>
                <TableCell>Initiative</TableCell>
                <TableCell>Lead</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
              </TableHead>
              <TableBody>
                {unmonitoredInitiatives.map((initiative) => (
                  <TableRow key={initiative.id}>
                    <TableCell>
                      <strong className="me-3">{initiative.numbering}</strong>
                      <a className="link-dark-no-underline" href={initiative.search_result_path}>
                        {initiative.name}
                      </a>
                    </TableCell>
                    <TableCell>{initiative.assigned_to_user?.full_name}</TableCell>
                    <TableCell>{initiative.start_date && new Date(initiative.start_date).toLocaleDateString({}, { timeZone: 'UTC' })}</TableCell>
                    <TableCell>{initiative.end_date && new Date(initiative.end_date).toLocaleDateString({}, { timeZone: 'UTC' })}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <Container {...props}></Container>
    </Layout>
  );
};
