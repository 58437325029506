import React from 'react';
import { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useFormStatus } from '../../helpers/form_hooks';
import ProgressMonitoringCycleResource from '../../resources/progress_monitoring_cycle_resource';
import Tooltip from '@mui/material/Tooltip';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import EditAction from '../../components/actions/edit';
import DeleteAction from '../../components/actions/delete';
import { useController } from 'rest-hooks';

// eslint-disable-next-line
const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option) => option.full_name,
});

export default ({ id, ...props }) => {
  const { processWithErrorFallback, isEditing, errors, loading, setEditing, formData, handleChange, handleCancel } = useFormStatus({
    props: props.cycle,
    fields: ['progress_report_due_date', 'reviewer_id'],
  });

  const { fetch } = useController();
  const { cycle } = props;

  const updateCycle = () => {
    const fnPromise = fetch(ProgressMonitoringCycleResource.update(), { id: cycle.id }, formData).then((response) => {
      props.replaceCycle(response);
    });
    processWithErrorFallback(fnPromise);
  };

  const deleteCycle = () => {
    const confirmDelete = confirm('Are you sure you want to delete this cycle?');
    if (confirmDelete) {
      fetch(ProgressMonitoringCycleResource.delete(), { id: cycle.id }).then(() => props.deleteCycle(cycle));
    }
  };

  return (
    <>
      <TableRow className={`cycle-table-row ${isEditing && 'editing'}`}>
        <TableCell>{cycle.reporter.full_name}</TableCell>
        <TableCell>{cycle.reviewer.full_name}</TableCell>
        <TableCell>
          <Tooltip
            arrow
            title={cycle.monitored_initiatives
              .sort((a, b) => {
                const [aSpNumber, aInitNumber] = a.numbering.split('.').map((num) => parseInt(num));
                const [bSpNumber, bInitNumber] = b.numbering.split('.').map((num) => parseInt(num));
                if (aSpNumber < bSpNumber) {
                  return -1;
                } else if (aSpNumber == bSpNumber) {
                  return aInitNumber - bInitNumber;
                } else {
                  return 1;
                }
              })
              .map((i) => i.numbering)
              .join(', ')}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: '#346189',
                  padding: '10px',
                  fontWeight: 400,
                  fontSize: 14,
                  opacity: 1,
                  lineHeight: 1.1,
                  '& .MuiTooltip-arrow': {
                    color: '#346189',
                    opacity: 1,
                  },
                },
              },
            }}
          >
            <div style={{ width: 'fit-content' }}>{cycle.monitored_initiatives.length}</div>
          </Tooltip>
        </TableCell>
        <TableCell>{new Date(cycle.progress_report_due_date.slice(0, -1)).toLocaleDateString()}</TableCell>
        <TableCell>
          {!isEditing && (
            <div className="d-flex">
              {cycle.modifiable && (
                <>
                  <EditAction handleClick={() => setEditing(!isEditing)} />
                  <DeleteAction handleClick={deleteCycle} />
                </>
              )}
            </div>
          )}
          {isEditing && (
            <div className="w-100 d-flex justify-content-center" onClick={() => setEditing(false)}>
              &#x2715;
            </div>
          )}
        </TableCell>
      </TableRow>
      {isEditing && (
        <>
          <TableRow>
            <TableCell colSpan="3" style={{ borderBottom: 0 }}>
              <div className="edit-cycle-form">
                <div className="d-flex">
                  <FormControl className="w-100">
                    <InputLabel>Reviewer</InputLabel>
                    <Select label="Reviewer" onChange={(e) => handleChange('reviewer_id', e)} value={formData.reviewer_id}>
                      {props.reviewers.map((reviewer) => (
                        <MenuItem key={reviewer.id} value={reviewer.id}>
                          {reviewer.full_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </TableCell>
            <TableCell style={{ borderBottom: 0 }}>
              <TextField
                type="date"
                inputProps={{ 'data-cy': 'due-date-input' }}
                label="Progress Report Due Date"
                defaultValue={formData.progress_report_due_date.split('T')[0]}
                onChange={(e) => handleChange('progress_report_due_date', e)}
              />
            </TableCell>
          </TableRow>
          <TableRow style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
            <TableCell style={{ borderBottom: 0 }} colSpan={2}>
              <button className="btn btn-att-gray-medium me-2" onClick={handleCancel}>
                Cancel
              </button>
              <button className="btn btn-att-blue-medium" onClick={updateCycle}>
                Save
              </button>
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
};
