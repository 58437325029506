import React, { useState } from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import DeleteAction from '../actions/delete';
import { useFormStatus } from '../../helpers/form_hooks';
import ProgressMonitoringCycleResource from '../../resources/progress_monitoring_cycle_resource';
import { useController } from 'rest-hooks';

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option) => option.full_name,
});

const cycleConfigProps = { initiative_lead_ids: [], reviewer_id: null, dates: ['', '', ''] };
// eslint-disable-next-line
export default ({ id, ...props }) => {
  const { processWithErrorFallback } = useFormStatus({
    props: cycleConfigProps,
    fields: ['initiative_lead_ids', 'reviewer_id', 'dates'],
  });

  const [initiativeLeads, setInitiativeLeads] = useState([]);
  const [reviewerId, setReviewerId] = useState(null);
  const [dates, setDates] = useState([null, null, null]);

  const { fetch } = useController();

  const removeDateAtIndex = (index) => {
    const newDates = [...dates];
    newDates.splice(index, 1);
    setDates(newDates);
  };

  const updateDateAtIndex = (index, event) => {
    const newDates = [...dates];
    newDates.splice(index, 1, event.target.value);
    setDates(newDates);
  };

  const addDate = () => {
    const newDates = [...dates];
    newDates.push(null);
    setDates(newDates);
  };

  const handleSubmit = () => {
    const state = {
      initiative_lead_ids: initiativeLeads.map((lead) => lead.id),
      reviewer_id: reviewerId,
      dates: dates,
    };
    const fnPromise = fetch(ProgressMonitoringCycleResource.bulkCreate(), {
      ...state,
      project_plan_id: props.project_plan.id,
    }).then((response) => {
      props.replaceCycles(response);
      handleClear();
    });
    processWithErrorFallback(fnPromise);
  };

  const handleClear = () => {
    setInitiativeLeads([]);
    setReviewerId(null);
    setDates(['', '', '']);
  };

  const addLead = (e, value, reason) => {
    if (reason == 'selectOption' && value.filter((option) => option.id == 'all').length > 0) {
      setInitiativeLeads(props.leads);
    } else {
      setInitiativeLeads(value);
    }
  };

  return (
    <div className="new-cycles-form">
      <h4 className="blue-header">Add New Cycles</h4>
      <Autocomplete
        options={[{ id: 'all', full_name: 'All Leads' }, ...props.leads]}
        filterOptions={filterOptions}
        filterSelectedOptions
        renderInput={(params) => <TextField {...params} label="Initiative lead(s)" />}
        getOptionLabel={(option) => option.full_name}
        getOptionDisabled={(option) => initiativeLeads.length == props.leads.length && option.id == 'all'}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={addLead}
        value={initiativeLeads}
        renderOption={(props, item) => (
          <li {...props} key={item.id} value={item.id} id={item.id}>
            {item.full_name}
          </li>
        )}
        multiple
      />

      <div className="mt-3" style={{ borderLeft: '3px solid #DFE8ED', paddingLeft: 15 }}>
        <FormControl className="w-100 select-reviewer">
          <InputLabel>Reviewer</InputLabel>
          <Select label="Reviewer" onChange={(e) => setReviewerId(e.target.value)} value={reviewerId}>
            {props.reviewers.map((reviewer) => (
              <MenuItem key={reviewer.id} value={reviewer.id} className="reviewer-option">
                {reviewer.full_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <h6 className="mt-3 blue-header">Progress Report Due Dates</h6>
        {dates.map((date, index) => (
          <div className="no-legend" key={index}>
            <OutlinedInput
              className="mt-3"
              inputProps={{ 'data-cy': 'date-select' }}
              type="date"
              value={date}
              onChange={(e) => updateDateAtIndex(index, e)}
            />
            <DeleteAction handleClick={() => removeDateAtIndex(index)} />
          </div>
        ))}
        <div className="mt-3">
          <button className="btn btn-att-gray-small" onClick={addDate}>
            Add a Date
          </button>
        </div>
      </div>
      <div className="d-flex mt-3">
        <button className="btn btn btn-att-blue-medium me-2 create-cycle-button" onClick={handleSubmit}>
          Create
        </button>
        <button className="btn btn btn-att-gray-medium" onClick={handleClear}>
          Clear
        </button>
      </div>
    </div>
  );
};
