import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import Modal from 'react-modal';

const DragDropFileUpload = (props) => {
  // drag state
  // eslint-disable-next-line
  const [dragActive, setDragActive] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [linkUrl, setLinkUrl] = useState('');
  const [linkDisplayText, setLinkDisplayText] = useState('');
  const [errors, setErrors] = useState({});

  // handle drag events
  // eslint-disable-next-line
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (acceptedFiles) => {
    setDragActive(false);

    Promise.all(
      acceptedFiles.map((file) => {
        const data = new FormData();
        data.append('file', file);
        setTimeout(() => {}, 500);
        const url = props.url;
        return fetch(url, {
          method: 'PUT',
          body: data,
        }).then((resp) => resp.json());
      })
    ).then((files) => props.handleUpload(files));
  };

  const displayLinkModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const resetModal = () => {
    setIsModalOpen(false);
    setLinkUrl('');
    setLinkDisplayText('');
    setErrors({});
  };

  const createLink = () => {
    const url = props.url;
    const data = new FormData();
    data.append('url', linkUrl);
    data.append('display_text', linkDisplayText);
    return fetch(url, {
      method: 'PUT',
      body: data,
    })
      .then((resp) => {
        return resp.json();
      })
      .then((response) => {
        if (response.errors) {
          setErrors(response.errors);
        } else {
          resetModal();
          props.handleUpload([response]);
        }
      });
  };

  return (
    <Dropzone onDrop={handleDrop}>
      {({ getRootProps, getInputProps }) => (
        <section className="border w-100 d-flex justify-content-center align-items-center" style={{ height: 114 }}>
          <Modal
            isOpen={isModalOpen}
            style={{
              content: {
                width: 509,
                margin: 'auto',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                height: 'fit-content',
              },
            }}
          >
            <h5 className="modal-title">Add Link</h5>
            <label className="mt-4 mb-2">Text to display</label>
            <input className="w-100" value={linkDisplayText} onChange={(e) => setLinkDisplayText(e.target.value)} />

            <label className="mt-4 mb-2">Link to document</label>
            <input className="w-100" value={linkUrl} onChange={(e) => setLinkUrl(e.target.value)} />

            {errors.url && <div className="error-text">Please enter a valid URL.</div>}

            <div className="d-flex w-100 justify-content-end mt-4">
              <button className="btn btn-att-gray-small me-2" onClick={resetModal}>
                {' '}
                Cancel{' '}
              </button>
              <button className="btn btn-att-blue-small" onClick={createLink}>
                {' '}
                Save{' '}
              </button>
            </div>
          </Modal>
          <div {...getRootProps()} className="d-flex flex-column align-items-center">
            <input {...getInputProps()} />
            <p className="text-center">Drag/drop files here or click below</p>
            <div className="d-flex">
              <button className="btn btn-att-gray-small me-2">Add a File</button>
              <button className="btn btn-att-gray-small" onClick={displayLinkModal}>
                Add a Link
              </button>
            </div>
          </div>
        </section>
      )}
    </Dropzone>
  );
};

export default DragDropFileUpload;
