import * as React from 'react';
import Modal from 'react-modal';
import alertIcon from 'images/alert.png';

const customStyles = {
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: 509,
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
};

export default (props) => {
  return (
    <Modal
      className="confirmation-modal"
      isOpen={props.isOpen}
      onAfterOpen={props.afterOpenModal}
      onRequestClose={props.closeModal}
      style={customStyles}
    >
      <h1 className="modal-title d-flex align-items-center">
        <img className="me-2" src={alertIcon} height="20px"></img>
        {props.modalTitle}
      </h1>
      <p className="modal-warning" style={{ whiteSpace: 'pre-line' }}>
        {props.modalWarning}
      </p>
      <div className="d-flex justify-content-end">
        <button className="btn btn-att-gray-small me-2" onClick={props.closeModal}>
          Cancel
        </button>
        <button className="btn btn-att-blue-small" onClick={props.onConfirm}>
          Accept
        </button>
      </div>
    </Modal>
  );
};
