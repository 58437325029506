import * as React from 'react';
import dueLater from 'images/dueSoonGray.png';
import dueLater2x from 'images/dueSoonGray@2x.png';
import overdue from 'images/overdueRed.png';
import dueSoon from 'images/due_soon.png';
import dueSoon2x from 'images/due_soon@2x.png';
import overdue2x from 'images/overdueRed@2x.png';
import complete from 'images/complete.png';
import complete2x from 'images/complete@2x.png';
import pendingLead from 'images/pendingLead.png';
import pendingLead2x from 'images/pendingLead@2x.png';
import pendingLeadInactive from 'images/pendingLeadInactive.png';
import pendingLeadInactive2x from 'images/pendingLeadInactive@2x.png';
import pendingReviewer from 'images/pendingReviewer.png';
import pendingReviewer2x from 'images/pendingReviewer@2x.png';
import pendingReviewerInactive from 'images/pendingReviewerInactive.png';
import pendingReviewerInactive2x from 'images/pendingReviewerInactive@2x.png';
import pendingMeeting from 'images/pendingMeeting.png';
import pendingMeeting2x from 'images/pendingMeeting@2x.png';
import Tooltip from '@mui/material/Tooltip';
import _ from 'lodash';

export default (props) => {
  if (props.status == 'incomplete') {
    return null;
  }

  const icon = () => {
    if (props.timeliness == 'overdue' && !props.ignoreTimeliness) {
      return <img src={overdue} srcSet={overdue2x} className="w-100" />;
    } else if (props.timeliness == 'due_soon' && !props.ignoreTimeliness) {
      return <img src={dueSoon} srcSet={dueSoon2x} className="w-100" />;
    } else if (props.status == 'pending_lead' && !props.onlyTimeliness) {
      if (props.role == 'lead') {
        return <img src={pendingLead} srcSet={pendingLead2x} className="w-100" />;
      } else {
        return <img src={pendingLeadInactive} srcSet={pendingLeadInactive2x} className="w-100" />;
      }
    } else if (props.status == 'pending_reviewer' && !props.onlyTimeliness) {
      if (props.role == 'reviewer') {
        return <img src={pendingReviewer} srcSet={pendingReviewer2x} className="w-100" />;
      } else {
        return <img src={pendingReviewerInactive} srcSet={pendingReviewerInactive2x} className="w-100" />;
      }
    } else if (props.status == 'pending_meeting' && !props.onlyTimeliness) {
      return <img src={pendingMeeting} srcSet={pendingMeeting2x} className="w-100" />;
    } else if (props.status == 'complete') {
      return <img src={complete} srcSet={complete2x} className="w-100" />;
    } else if (props.onlyTimeliness) {
      return <img src={dueLater} srcSet={dueLater2x} className="w-100" />;
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-between me-2" style={{ width: 20, height: 20 }}>
      {props.showTooltip && (
        <Tooltip
          title={
            props.onlyTimeliness || (props.timeliness == 'overdue' && !props.ignoreTimeliness)
              ? props.timeliness
                  .split('_')
                  .map((s) => _.startCase(s))
                  .join(' ')
              : props.status
                  .split('_')
                  .map((s) => _.startCase(s))
                  .join(' ')
          }
          arrow
          placement="top"
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: '#346189',
                padding: '10px',
                fontWeight: 400,
                fontSize: 14,
                opacity: 1,
                lineHeight: 1.1,
                '& .MuiTooltip-arrow': {
                  color: '#346189',
                  opacity: 1,
                },
              },
            },
          }}
        >
          {icon()}
        </Tooltip>
      )}
      {!props.showTooltip && icon()}
    </div>
  );
};
