import RailsResource from './rails_resource';

export default class ProgressReportResource extends RailsResource {
  id = undefined;
  measures_meet_or_exceed_target = undefined;
  measures_not_meet_or_exceed_target_reason = undefined;
  adjust_timeline_reason = undefined;
  measures_reported_on_time = undefined;
  most_important_to_discuss = undefined;
  not_on_track_reason = undefined;
  on_track = undefined; // TODO: Deprecate
  on_track_status = undefined;
  status = undefined;

  pk() {
    return this.id;
  }

  static urlRoot = '/progress_reports/';
}

/**
#  id                  :bigint           not null, primary key
#  description         :text
#  end_on              :date
#  position            :integer
#  start_on            :date
#  status              :string
#  created_at          :datetime         not null
#  updated_at          :datetime         not null
#  assigned_to_user_id :integer
#  initiative_id       :integer
#
*/
