import React, { useState } from 'react';
import Layout from '../layouts/application';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ProgressReport from '../../components/progress_report/index';
import { createApi as createProgressReportsApi } from '../../apis/progress_reports/api';

const Container = (props) => {
  const api = createProgressReportsApi();
  const [reports, setReports] = useState(props.progress_reports);

  const handleDelete = async (id) => {
    if (confirm('Are you sure you want to delete this report?')) {
      try {
        await api.delete(id);
        setReports(reports.filter((report) => report.id !== id));
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 50, paddingLeft: 0 }}>Num</TableCell>
            <TableCell>Description</TableCell>
            <TableCell style={{ width: 175 }}>Status</TableCell>
            <TableCell style={{ width: 150 }}>Due Date</TableCell>
            <TableCell style={{ width: 55 }} />
          </TableRow>
        </TableHead>
      </Table>
      {reports
        .sort((a, b) => {
          if (a.initiative?.numbering == null || b.initiative?.numbering == null) {
            return -1;
          }
          const [aSpNumber, aInitNumber] = a.initiative.numbering.split('.').map((num) => parseInt(num));
          const [bSpNumber, bInitNumber] = b.initiative.numbering.split('.').map((num) => parseInt(num));
          if (aSpNumber < bSpNumber) {
            return -1;
          } else if (aSpNumber == bSpNumber) {
            return aInitNumber - bInitNumber;
          } else {
            return 1;
          }
        })
        .map((report) => (
          <ProgressReport
            key={report.id}
            report={report}
            view={props.view}
            archive={props.archive}
            facilitator={props.facilitator}
            currentUserId={props.current_user_id}
            isCurrentUserSuperAdmin={props.is_current_user_superadmin}
            handleDelete={handleDelete}
          />
        ))}
    </div>
  );
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <Container {...props}></Container>
    </Layout>
  );
};
