import React, { useState } from 'react';
import Layout from '../layouts/application';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import _ from 'lodash';

const Container = (props) => {
  const [leadId, setLeadId] = useState(false);

  return (
    <Paper elevation={3} className="p-3">
      <div className="d-flex justify-content-between">
        <h5 className="ps-3">Archived Progress Reports</h5>
        {props.show_lead_dropdown && (
          <Select size="small" className="col-3 ms-3 no-legend" value={leadId}>
            <MenuItem onClick={() => setLeadId(false)} value={leadId} selected={false}>
              All Leads
            </MenuItem>
            {props.leads.map((user) => (
              <MenuItem onClick={() => setLeadId(user.id)} key={user.id} value={user.id}>
                {user.full_name}
              </MenuItem>
            ))}
          </Select>
        )}
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="col-2 pb-1 blue-table-header">Lead</TableCell>
            <TableCell className="col-5 pb-1 blue-table-header">Reviewer</TableCell>
            <TableCell className="col-5 pb-1 blue-table-header">Initiative</TableCell>
            <TableCell className="col-2 pb-1 blue-table-header">Due Date</TableCell>
            <TableCell className="col-2 pb-1 blue-table-header">Stage</TableCell>
            <TableCell className="col-1 pb-1 blue-table-header" />
          </TableRow>
        </TableHead>
        <TableBody>
          {props.reports
            .filter((report) => leadId == false || leadId == report.reporter.id)
            .map((report) => (
              <TableRow key={report.id}>
                <TableCell className="col-2">{report.reporter.full_name}</TableCell>
                <TableCell className="col-2">{report.reviewer.full_name}</TableCell>
                <TableCell className="col-5">
                  <strong className="me-3"> {report.initiative.numbering} </strong>
                  {report.initiative.name}
                </TableCell>
                <TableCell className="col-2">{new Date(report.due_date).toLocaleDateString({}, { timeZone: 'UTC' })}</TableCell>
                <TableCell className="col-2">{_.startCase(report.status.split('_').join(' '))}</TableCell>
                <TableCell className="col-1">
                  <a className="btn btn-att-gray-small" href={report.show_path + '?view=archive'}>
                    View
                  </a>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <Container {...props}></Container>
    </Layout>
  );
};
