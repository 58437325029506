import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import Paper from '@mui/material/Paper';
import Resources from '../../components/shared/resources';
import MeasureTable from '../../components/measure/table';
import DragDropFileUpload from '../../components/shared/drag_drop_file_upload';
import { useFormStatus } from '../../helpers/form_hooks';
import { useController } from 'rest-hooks';
import ProgressReportResource from '../../resources/progress_report_resource';
import railsFetch from '../../helpers/rails_fetch';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReportIcon from './icon';
import ReportStatus from './report_status';
import ConfirmModal from '../../components/modals/confirm_modal';
import CircularProgress from '@mui/material/CircularProgress';
import checkIcon from '../../../../images/pr-submitted.png';
import _ from 'lodash';
import ResourcesList from '../shared/resources_list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { trackCzEvent } from '../../helpers/track_cz_event';
import RadioStatusGroup from '../shared/radio_status_group';
import { RedYellowGreen } from '../../constants/red_yellow_green';

const InitiativeReport = (props) => {
  const isStatusNotOnTrack = props.formData.on_track_status === 'red' || props.formData.on_track_status === 'yellow';
  const [notOnTrackReasonShowing, setNotOnTrackReasonShowing] = useState(isStatusNotOnTrack);
  const radioStatusOptions = [
    { value: 'green', label: 'On track', description: 'making solid progress on deliverables', color: RedYellowGreen.green },
    { value: 'yellow', label: 'Moving along', description: 'but not at original pace', color: RedYellowGreen.yellow },
    { value: 'red', label: 'Off track', description: 'not progressing at all on deliverables', color: RedYellowGreen.red },
  ];

  return (
    <Paper className="me-3" elevation={0}>
      <Stack direction="row" justifyContent="flex-end" spacing={5}>
        <Typography variant="body1">
          <Stack direction="row" spacing={1}>
            <Typography sx={{ fontWeight: 'bold' }}>Lead:</Typography>
            <Typography>{props.report.reporter.full_name}</Typography>
          </Stack>
        </Typography>
        <Typography variant="body1">
          <Stack direction="row" spacing={1}>
            <Typography sx={{ fontWeight: 'bold' }}>Reviewer:</Typography>
            <Typography>{props.report.reviewer.full_name}</Typography>
          </Stack>
        </Typography>
      </Stack>
      <h4>Qualitative Report</h4>
      <p className="mt-4 me-3 prompt">
        What is the status of this initiative at this time? (Green / Yellow / Red)
        {'\u00A0'}
        <strong className="text-danger">*</strong>
        {'\u00A0'}
      </p>

      <div className="mt-2">
        <RadioStatusGroup
          inlineDescription={true}
          options={radioStatusOptions}
          value={props.formData.on_track_status}
          onChange={(e) => {
            props.handleChange('on_track_status', e);
            setNotOnTrackReasonShowing(e.target.value === 'red' || e.target.value === 'yellow');
          }}
          name="ontrack-status"
          disabled={props.view === 'reviewer'}
          error={props.errors.on_track_status?.length > 0 ? props.errors.on_track_status[0] : ''}
          persistDescriptions
        />
      </div>

      {props.view === 'reporter' && notOnTrackReasonShowing && (
        <div className="ms-4 mb-4 border-start ps-3">
          <p className="prompt mb-2">
            Briefly explain your response below: <strong className="text-danger">*</strong>
          </p>
          <div className="me-3">
            <TextField
              error={props.errors.not_on_track_reason?.length > 0}
              readOnly={props.view !== 'reporter'}
              className="w-100 no-label"
              multiline
              value={props.formData.not_on_track_reason}
              onChange={(e) => props.handleChange('not_on_track_reason', e)}
            />
            <FormHelperText className="text-danger">{props.errors.not_on_track_reason?.join(' ')}</FormHelperText>
          </div>
        </div>
      )}
      {props.view === 'reviewer' && isStatusNotOnTrack && (
        <div className="ms-4 mb-4 border-start ps-3">
          <p className="prompt mb-2">
            Briefly explain your response below: <strong className="text-danger">*</strong>
          </p>
          <div className="me-3">
            <p className="mb-0" style={{ whiteSpace: 'pre-wrap' }}>
              {props.formData.not_on_track_reason}
            </p>
          </div>
        </div>
      )}

      <div className="mt-2">
        <p className="prompt">
          What are you most proud of in your work on this initiative during this period? What highlights do you have to share and celebrate?{'\u00A0'}
          <strong className="text-danger">*</strong>
        </p>
        {props.view === 'reviewer' ? (
          <p style={{ overflowWrap: 'anywhere', whiteSpace: 'pre-wrap' }}>{props.formData.most_proud_of}</p>
        ) : (
          <TextField
            error={props.errors.most_proud_of?.length > 0}
            className="w-100 no-label"
            multiline
            readOnly={props.view !== 'reporter'}
            value={props.formData.most_proud_of != null ? props.formData.most_proud_of : props.report.most_proud_of}
            onChange={(e) => props.handleChange('most_proud_of', e)}
          />
        )}
        <FormHelperText className="text-danger">{props.errors.most_proud_of?.join(' ')}</FormHelperText>
      </div>
      <div>
        <p className="prompt">
          Are there any current challenges you would like to discuss or any needs for collaboration and discussion with other team members? If so,
          please explain.{'\u00A0'}
          <strong className="text-danger">*</strong>
        </p>
        {props.view === 'reviewer' ? (
          <p style={{ overflowWrap: 'anywhere', whiteSpace: 'pre-wrap' }}>{props.formData.most_important_to_discuss}</p>
        ) : (
          <TextField
            error={props.errors.most_important_to_discuss?.length > 0}
            className="w-100 no-label"
            multiline
            readOnly={props.view !== 'reporter'}
            value={
              props.formData.most_important_to_discuss != null ? props.formData.most_important_to_discuss : props.report.most_important_to_discuss
            }
            onChange={(e) => props.handleChange('most_important_to_discuss', e)}
          />
        )}
        <FormHelperText className="text-danger">{props.errors.most_important_to_discuss?.join(' ')}</FormHelperText>
      </div>
    </Paper>
  );
};

const DeliverablesReport = (props) => {
  return (
    <Accordion className="mb-3 mt-5 deliverables-report" elevation={0}>
      <AccordionSummary className="p-3 ps-0" expandIcon={<ExpandMoreIcon />}>
        <div className="d-flex">
          <h5 className="m-0">Deliverables</h5>
          <div className="d-flex position-absolute" style={{ left: '50%', transform: 'translateX(-50%) translateY(-50%)', top: '50%' }}>
            <div className="me-5 text-center">
              <small>Total</small>
              <p style={{ fontSize: 20 }}>{props.report.deliverables.length}</p>
            </div>
            <div className="me-5 text-center">
              <small>Completed</small>
              <p style={{ fontSize: 20 }}>{props.report.deliverables.filter((d) => d.completed).length}</p>
            </div>
            <div className="me-5 text-center">
              <small>Overdue</small>
              <p style={{ fontSize: 20 }}>{props.report.deliverables.filter((d) => d.status == 'overdue').length}</p>
            </div>
            <div className="text-center">
              <small>Due in 30 Days</small>
              <p style={{ fontSize: 20 }}>{props.report.deliverables.filter((d) => d.due_in_30_days).length}</p>
            </div>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Table size="small">
          <TableHead>
            <TableCell>Num</TableCell>
            <TableCell>Deliverable</TableCell>
            <TableCell>Start</TableCell>
            <TableCell>End</TableCell>
            <TableCell>Complete</TableCell>
            <TableCell>Resources</TableCell>
          </TableHead>
          <TableBody>
            {props.report.deliverables.map((deliverable) => (
              <TableRow key={deliverable.id}>
                <TableCell>{deliverable.numbering}</TableCell>
                <TableCell>{deliverable.description}</TableCell>
                <TableCell>{deliverable.start_on && new Date(deliverable.start_on).toLocaleDateString({}, { timeZone: 'UTC' })}</TableCell>
                <TableCell>{deliverable.end_on && new Date(deliverable.end_on).toLocaleDateString({}, { timeZone: 'UTC' })}</TableCell>
                <TableCell align="center">
                  {deliverable.completed && <FontAwesomeIcon icon={faCircleCheck} style={{ width: 18, height: 18, color: '7CB9B9' }} />}
                </TableCell>
                <TableCell>
                  <Resources resources={deliverable.resources} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
};

const QuantitativeQuestions = (props) => {
  const [showOnTimeExplanation, setShowOnTimeExplanation] = useState(
    props.formData.measures_reported_on_time != null && !$.parseJSON(props.formData.measures_reported_on_time)
  );
  const [showExceedExplanation, setShowExceedExplanation] = useState(
    props.formData.measures_meet_or_exceed_target != null && !$.parseJSON(props.formData.measures_meet_or_exceed_target)
  );

  return (
    <>
      <div className="d-flex align-items-center">
        <p className="m-0 me-3 prompt">
          Will your upcoming measures be reported on time and with quality?{'\u00A0'}
          <strong className="text-danger">*</strong>
          {'\u00A0'}
        </p>
        <FormControl error={props.errors.measures_reported_on_time?.length > 0}>
          <div className="d-flex align-items-center">
            <RadioGroup row>
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Yes"
                labelPlacement="end"
                checked={props.formData.measures_reported_on_time != undefined && $.parseJSON(props.formData.measures_reported_on_time)}
                onChange={(e) => {
                  props.handleChange('measures_reported_on_time', e);
                  setShowOnTimeExplanation(false);
                }}
                disabled={props.view == 'reviewer'}
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="No"
                labelPlacement="end"
                checked={props.formData.measures_reported_on_time != undefined && !$.parseJSON(props.formData.measures_reported_on_time)}
                onChange={(e) => {
                  props.handleChange('measures_reported_on_time', e);
                  setShowOnTimeExplanation(true);
                }}
                disabled={props.view == 'reviewer'}
              />
            </RadioGroup>
            <FormHelperText>{props.errors.measures_reported_on_time?.join(' ')}</FormHelperText>
          </div>
        </FormControl>
      </div>
      {props.view == 'reporter' && showOnTimeExplanation && (
        <div className="ms-3">
          <p>
            If not, why not? <strong className="text-danger">*</strong>
          </p>
          <TextField
            error={props.errors.adjust_timeline_reason?.length > 0}
            className="w-50 no-label"
            defaultValue={props.report.adjust_timeline_reason}
            onChange={(e) => props.handleChange('adjust_timeline_reason', e)}
            multiline
          />
          <FormHelperText className="text-danger">{props.errors.adjust_timeline_reason?.join(' ')}</FormHelperText>
        </div>
      )}
      {props.view == 'reviewer' && showOnTimeExplanation && (
        <div className="ms-3">
          <p>
            If not, why not? <strong className="text-danger">*</strong>
          </p>
          <div style={{ whiteSpace: 'pre-wrap' }}>{props.report.adjust_timeline_reason}</div>
        </div>
      )}
      <div className="d-flex align-items-center">
        <p className="me-3 prompt">
          Do you believe that you will meet/exceed your upcoming measures?{'\u00A0'}
          <strong className="text-danger">*</strong>
          {'\u00A0'}
        </p>
        <FormControl error={props.errors.measures_meet_or_exceed_target?.length > 0}>
          <div className="d-flex align-items-center">
            <RadioGroup row>
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Yes"
                labelPlacement="end"
                checked={props.formData.measures_meet_or_exceed_target != undefined && $.parseJSON(props.formData.measures_meet_or_exceed_target)}
                onChange={(e) => {
                  props.handleChange('measures_meet_or_exceed_target', e);
                  setShowExceedExplanation(false);
                }}
                disabled={props.view == 'reviewer'}
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="No"
                labelPlacement="end"
                checked={props.formData.measures_meet_or_exceed_target != undefined && !$.parseJSON(props.formData.measures_meet_or_exceed_target)}
                onChange={(e) => {
                  props.handleChange('measures_meet_or_exceed_target', e);
                  setShowExceedExplanation(true);
                }}
                disabled={props.view == 'reviewer'}
              />
            </RadioGroup>
            <FormHelperText className="text-danger">{props.errors.measures_meet_or_exceed_target?.join(' ')}</FormHelperText>
          </div>
        </FormControl>
      </div>
      {props.view == 'reporter' && showExceedExplanation && (
        <div className="ms-3">
          <p className="prompt">
            If not, why not? <strong className="text-danger">*</strong>
          </p>
          <TextField
            error={props.errors.measures_not_meet_or_exceed_target_reason?.length > 0}
            className="w-50 no-label mb-3"
            multiline
            defaultValue={props.report.measures_not_meet_or_exceed_target_reason}
            onChange={(e) => props.handleChange('measures_not_meet_or_exceed_target_reason', e)}
          />
          <FormHelperText className="text-danger">{props.errors.measures_not_meet_or_exceed_target_reason?.join(' ')}</FormHelperText>
        </div>
      )}
      {props.view == 'reviewer' && showExceedExplanation && (
        <div className="ms-3">
          <p className="prompt">
            If not, why not? <strong className="text-danger">*</strong>
          </p>
          <div style={{ whiteSpace: 'pre-wrap' }}>{props.report.measures_not_meet_or_exceed_target_reason}</div>
        </div>
      )}
    </>
  );
};

const MeasuresReport = (props) => {
  return (
    <Paper elevation={0}>
      <AccordionSummary style={{ cursor: 'initial' }} className="ps-0 pm-quant-heading">
        <h5 className="m-0">Quantitative Report</h5>
      </AccordionSummary>
      <AccordionDetails className="ps-0 pt-0">
        {props.report.requires_quantitative_answers && <QuantitativeQuestions {...props} />}
        {!props.report.requires_quantitative_answers && (
          <div className="w-50">
            You do not have any Measures due in the next 60 days. You do not need to respond to the Quantitative Report section.
          </div>
        )}
        <div className="mt-3">
          <MeasureTable measures={props.report.measures} measurableType="Initiative" noMargin hideActions hideDelegations />
        </div>
      </AccordionDetails>
    </Paper>
  );
};

const WorkProducts = (props) => {
  const handleDelete = (id) => {
    const confirmDelete = confirm('Are you sure you want to delete this work product?');
    if (confirmDelete) {
      // eslint-disable-next-line
      const fnPromise = railsFetch(`/attachments/${id}`, {
        method: 'DELETE',
      })
        .then((res) => res.json())
        .then((file) => props.handleDelete(file));
    }
  };

  const shouldShowAttachments = () => {
    return props.view == 'reporter' || (props.view == 'reviewer' && (props.reportStatus == 'pending_reviewer' || props.reportStatus == 'complete'));
  };

  return (
    <Paper className="mt-3" elevation={0}>
      <h5>Work Product for Review</h5>
      <div>
        {shouldShowAttachments() && (
          <div className="mt-3">
            <ResourcesList resources={props.attachments} handleDelete={handleDelete} canEdit={props.view === 'reporter'} />
          </div>
        )}
        {props.view === 'reporter' && (
          <div className="mt-3">
            <DragDropFileUpload url={`/progress_reports/${props.report.id}/attach_file`} handleUpload={props.handleFileUpload} />
          </div>
        )}
      </div>
    </Paper>
  );
};

export default (props) => {
  const { report } = props;
  let viewablePrFields;

  // eslint-disable-next-line
  const { supervisor_notes, ...reporter_fields } = report;

  switch (report.status) {
    case 'pending_lead':
      viewablePrFields = props.view === 'reporter' ? reporter_fields : {};
      break;
    case 'pending_reviewer':
      viewablePrFields = props.view === 'reporter' ? reporter_fields : report;
      break;
    case 'pending_meeting':
      viewablePrFields = report;
      break;
    case 'incomplete':
      viewablePrFields = report;
      break;
    case 'complete':
      viewablePrFields = report;
      break;
    default:
      viewablePrFields = {};
  }

  /* eslint-disable */
  const { processWithErrorFallback, errors, loading, formData, handleCancel, handleChange, handleEdit } = useFormStatus({
    props: viewablePrFields,
    fields: [
      'on_track_status',
      'not_on_track_reason',
      'most_important_to_discuss',
      'most_proud_of',
      'measures_meet_or_exceed_target',
      'measures_not_meet_or_exceed_target_reason',
      'measures_reported_on_time',
      'measures_not_reported_on_time_reason',
      'supervisor_notes',
    ],
  });
  /* eslint-enable */

  const { fetch } = useController();

  const [attachments, setAttachments] = useState(props.report && props.report.attachments);
  const [reportStatus, setReportStatus] = useState(report.status);
  const [expanded, setExpanded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [onModalConfirm, setOnModalConfirm] = useState(() => {});
  const [modalTitle, setModalTitle] = useState('');
  const [modalWarning, setModalWarning] = useState('');
  const [saveButtonStyle, setSaveButtonStyle] = useState('Save');

  if (props.report == null) {
    return;
  }

  const handleFileUpload = (newFiles) => {
    setAttachments([...attachments, ...newFiles]);
  };

  const handleFileDelete = (file) => {
    setAttachments([...attachments.filter((att) => att.id != file.id)]);
  };

  /* eslint-disable */
  const saveReport = () => {
    setSaveButtonStyle('Saving');
    processWithErrorFallback(
      fetch(ProgressReportResource.update(), { id: report.id }, formData).then((res) => {
        setSaveButtonStyle('Saved');
        setTimeout(() => {
          setSaveButtonStyle('Save');
        }, 3000);
        trackCzEvent(`ProgSave:${roleName()}`, `${roleName()} saved progress report ${report.id}`, 1);
      }),
      { withErrorThrow: true }
    ).catch((err) => {
      setSaveButtonStyle('Save');
    });
  };
  /* eslint-enable */

  /* eslint-disable */
  const submitReport = () => {
    const newStatus = props.view === 'reporter' ? 'pending_reviewer' : 'pending_meeting';
    processWithErrorFallback(
      fetch(ProgressReportResource.update(), { id: report.id }, { ...formData, status: newStatus }).then((res) => {
        setReportStatus(newStatus);
        trackCzEvent(`ProgSubmit:${roleName()}`, `${roleName()} submitted progress report ${report.id}`, 1);
      })
    );
    setIsModalOpen(false);
  };
  /* eslint-enable */

  /* eslint-disable */
  const completeReport = () => {
    // TO DO - save notes only if view == 'reviewer'
    processWithErrorFallback(
      fetch(ProgressReportResource.update(), { id: report.id }, { status: 'complete' }).then((res) => {
        setReportStatus('complete');
        setIsModalOpen(false);
        trackCzEvent(`ProgComplete:${roleName()}`, `${roleName()} completed progress report ${report.id}`, 1);
      })
    );
  };
  /* eslint-enable */

  /* eslint-disable */
  const abandonReport = () => {
    // TO DO - save notes only if view == 'reviewer'
    processWithErrorFallback(
      fetch(ProgressReportResource.update(), { id: report.id }, { status: 'incomplete' }).then((res) => {
        setReportStatus('incomplete');
        setIsModalOpen(false);
        trackCzEvent(`ProgAbandon:${roleName()}`, `${roleName()} abandoned progress report ${report.id}`, 1);
      })
    );
  };
  /* eslint-enable */

  const roleName = () => {
    if (props.view === 'reporter') {
      return 'Lead';
    } else if (props.view === 'reviewer' && !props.facilitator) {
      return 'Reviewer';
    } else if (props.facilitator) {
      return 'Facilitator';
    }
  };

  const shouldShowSave = () => {
    if (props.view === 'reporter') {
      return ['pending_lead', 'pending_reviewer'].includes(reportStatus);
    } else {
      return ['pending_reviewer', 'pending_meeting'].includes(reportStatus);
    }
  };

  const shouldShowSaveAndSubmit = () => {
    const viewerIsReviewer = props.report.reviewer.id === props.currentUserId;
    return (
      (props.view === 'reporter' && reportStatus === 'pending_lead') ||
      (props.view === 'reviewer' && reportStatus === 'pending_reviewer' && viewerIsReviewer)
    );
  };

  const shouldShowComplete = () => {
    return reportStatus === 'pending_meeting';
  };

  const shouldShowAbandon = () => {
    return (
      (props.view === 'reporter' && reportStatus !== 'incomplete' && reportStatus !== 'complete') ||
      (props.view === 'reviewer' && reportStatus === 'pending_meeting') ||
      reportStatus === 'pending_reviewer'
    );
  };

  const reviewerNotesShouldBeEditable = () => {
    return (props.view === 'reviewer' || props.facilitator) && ['pending_reviewer', 'pending_meeting'].includes(reportStatus);
  };

  const renderReviewerNotes = (errors) => {
    if (props.view === 'reporter' && ['pending_lead', 'pending_reviewer'].includes(reportStatus)) {
      return null;
    } else if (reviewerNotesShouldBeEditable()) {
      return (
        <Paper elevation={0} className="p-3 ps-0">
          <h5>
            Notes to {props.report.reporter.full_name} on Initiative {props.report.initiative.numbering}
          </h5>
          <TextField
            error={errors.supervisor_notes?.length > 0}
            readOnly={props.view === 'reporter'}
            className="w-100 no-label"
            multiline
            value={formData.supervisor_notes}
            onChange={(e) => handleChange('supervisor_notes', e)}
          />
          <FormHelperText className="text-danger">{errors.supervisor_notes?.join(' ')}</FormHelperText>
        </Paper>
      );
    } else if (
      reportStatus === 'complete' ||
      (props.view === 'reporter' && reportStatus === 'pending_meeting') ||
      (props.view === 'reviewer' && (reportStatus === 'complete' || reportStatus === 'incomplete')) ||
      (props.facilitator && !(reportStatus === 'pending_reviewer'))
    ) {
      return (
        <Paper elevation={0} className="p-3 ps-0">
          <h5>
            Notes from {props.report.reviewer.full_name} on Initiative {props.report.initiative.numbering}
          </h5>
          <p style={{ whiteSpace: 'pre-wrap' }}> {formData.supervisor_notes}</p>
        </Paper>
      );
    } else {
      return (
        <Paper elevation={0} className="p-3 ps-0">
          <h5>
            Notes to {props.report.reporter.full_name} on Initiative {props.report.initiative.numbering}
          </h5>
          <p>{props.supervisor_notes}</p>
        </Paper>
      );
    }
  };

  const shouldShowDelete = () => props.archive && props.isCurrentUserSuperAdmin;

  if (reportStatus === 'pending_lead' && props.view === 'reviewer') {
    return (
      <Table>
        <TableBody>
          <TableRow className="w-100">
            <TableCell style={{ width: 50, paddingLeft: 0 }}>
              <strong>{props.report.initiative.numbering}</strong>
            </TableCell>
            <TableCell>{props.report.initiative.description}</TableCell>
            <TableCell style={{ width: 175 }}>
              <div className="d-flex align-items-center">
                <ReportIcon
                  status={reportStatus}
                  role={props.view === 'reporter' ? 'lead' : 'reviewer'}
                  timeliness={report.timeliness}
                  ignoreTimeliness
                />
                {_.startCase(reportStatus.split('_').join(' '))}
              </div>
            </TableCell>
            <TableCell style={{ width: 150 }}>
              <div className="d-flex align-items-center">
                <div className="me-2">
                  <ReportIcon
                    status={reportStatus}
                    role={props.view === 'reporter' ? 'lead' : 'reviewer'}
                    timeliness={report.timeliness}
                    onlyTimeliness
                  />
                </div>
                <ReportStatus
                  timeliness={props.report.timeliness}
                  date={props.report.due_date}
                  overdue={props.report.overdue}
                  status={reportStatus}
                />
              </div>
            </TableCell>
            <TableCell style={{ width: 55 }}></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  } else {
    return (
      <div className="progress-report-list-item">
        <Accordion
          className="report-accordion"
          style={{ border: 0 }}
          defaultExpanded={props.expanded}
          elevation={0}
          sx={{ border: '0px !important' }}
        >
          <AccordionSummary className={`m-0 p-0 ${expanded && 'expanded'}`} onClick={() => setExpanded(!expanded)}>
            <Table>
              <TableBody>
                <TableRow className="w-100">
                  <TableCell style={{ width: 50, paddingLeft: 0 }}>
                    <strong>{props.report.initiative.numbering}</strong>
                  </TableCell>
                  <TableCell>{props.report.initiative.description}</TableCell>
                  <TableCell style={{ width: 175 }}>
                    <div className="d-flex align-items-center">
                      <ReportIcon
                        status={reportStatus}
                        role={props.view === 'reporter' ? 'lead' : 'reviewer'}
                        timeliness={report.timeliness}
                        ignoreTimeliness
                      />
                      {_.startCase(reportStatus.split('_').join(' '))}
                    </div>
                  </TableCell>
                  <TableCell style={{ width: 150 }}>
                    <div className="d-flex align-items-center">
                      <ReportIcon
                        status={reportStatus}
                        role={props.view === 'reporter' ? 'lead' : 'reviewer'}
                        timeliness={report.timeliness}
                        onlyTimeliness
                      />
                      <ReportStatus
                        timeliness={props.report.timeliness}
                        date={props.report.due_date}
                        status={reportStatus}
                        overdue={props.report.overdue}
                      />
                    </div>
                  </TableCell>
                  <TableCell style={{ width: 55 }}>
                    <ExpandMoreIcon style={{ color: 'gray', transform: `rotate(${expanded ? '180' : '0'}deg)` }} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </AccordionSummary>
          <AccordionDetails className="p-0">
            <div className="progress-report-container mb-4">
              <div className="mb-3">
                <InitiativeReport {...props} errors={errors} formData={formData} handleChange={handleChange} />
                <WorkProducts
                  attachments={attachments}
                  handleFileUpload={handleFileUpload}
                  handleDelete={handleFileDelete}
                  reportStatus={reportStatus}
                  {...props}
                />
              </div>
              <DeliverablesReport report={props.report} />
              <hr style={{ color: 'gray' }} />
              <div className="mb-3">
                <MeasuresReport {...props} errors={errors} formData={formData} handleChange={handleChange} />
              </div>
              {renderReviewerNotes(errors)}
              {shouldShowDelete() && (
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Button variant="outlined" onClick={() => props.handleDelete(props.report.id)}>
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              )}
              <div className=" d-flex justify-content-between mt-3 w-100">
                <div>
                  {!props.archive && shouldShowAbandon() && (
                    <button
                      className="btn btn-att-gray-medium px-3"
                      onClick={() => {
                        setIsModalOpen(true);
                        setOnModalConfirm(() => abandonReport);
                        setModalTitle('Archive as Incomplete?');
                        setModalWarning(`You are about to mark this progress report as “Incomplete” which:

                        1. Implies there was no meeting about this report, and
                        2. Moves the report to the Archive section.

                        This action cannot be undone. Do you wish to proceed?`);
                      }}
                    >
                      Report / Meeting Incomplete
                    </button>
                  )}
                </div>
                <div>
                  {!props.archive && shouldShowSave() && (
                    <button
                      className={`btn btn-att-${shouldShowSaveAndSubmit() || shouldShowComplete() ? 'gray' : 'blue'}-medium ${
                        (saveButtonStyle === 'Saving' || saveButtonStyle === 'Saved') && 'btn-disabled'
                      }`}
                      onClick={saveReport}
                      disabled={saveButtonStyle === 'Saving' || saveButtonStyle === 'Saved'}
                    >
                      <div className="d-flex align-items-center justify-content-center">
                        {saveButtonStyle}
                        {saveButtonStyle === 'Saving' && <CircularProgress size="0.75em" className="ms-2" />}
                        {saveButtonStyle === 'Saved' && <img src={checkIcon} className="ms-2" />}
                      </div>
                    </button>
                  )}
                  {!props.archive && shouldShowSaveAndSubmit() && (
                    <button
                      className="btn btn-att-blue-medium ms-3 px-3"
                      onClick={() => {
                        setIsModalOpen(true);
                        setOnModalConfirm(() => submitReport);
                        setModalTitle(`Submit to ${props.view === 'reporter' ? 'Reviewer' : 'Lead'}`);
                        if (props.view === 'reporter') {
                          setModalWarning('This will submit your progress report for review. This action cannot be undone. Do you wish to proceed?');
                        } else {
                          setModalWarning(
                            'This will send your progress report notes to the Lead. This action cannot be undone. Do you wish to proceed?'
                          );
                        }
                      }}
                    >
                      Submit to {props.view === 'reporter' ? 'Reviewer' : 'Lead'}
                    </button>
                  )}
                  {!props.archive && shouldShowComplete() && (
                    <button
                      className="btn btn-att-blue-medium ms-3 px-3"
                      onClick={() => {
                        setIsModalOpen(true);
                        setOnModalConfirm(() => completeReport);
                        setModalTitle('Mark as Complete?');
                        setModalWarning(`You are about to mark this progress report as “Complete” which:

                         1. Implies that your meeting about this initiative is complete
                         2. Moves the report to the Archive section.

                        This action cannot be undone. Do you wish to proceed?`);
                      }}
                    >
                      Report & Meeting Complete
                    </button>
                  )}
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <ConfirmModal
          isOpen={isModalOpen}
          modalTitle={modalTitle}
          modalWarning={modalWarning}
          closeModal={() => setIsModalOpen(false)}
          onConfirm={() => onModalConfirm()}
        />
      </div>
    );
  }
};
