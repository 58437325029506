import * as React from 'react';

export default (props) => {
  const icon = () => {
    // TO DO - fix later
    // if (props.overdue) {
    //   return <img style={{ width: 14, height: 14 }} src={overdue} height="20px" />;
    // } else if (props.staus == 'pending_meeting') {
    //   return <img style={{ width: 14, height: 14 }} src={submitted} height="20px" />;
    // } else {
    //   return <img style={{ width: 14, height: 14 }} src={pending} height="20px" />;
    // }
  };

  return (
    <div className="d-flex">
      <div className={`report-due-date ${props.overdue && 'overdue'} me-2`}>{new Date(props.date).toLocaleDateString({}, { timeZone: 'UTC' })}</div>
      <div>{icon()}</div>
    </div>
  );
};
