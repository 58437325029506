import React from 'react';
import ProgressReport from '../../components/progress_report/index';
import Layout from '../layouts/application';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../constants/theme';

const Container = (props) => {
  return (
    <ProgressReport
      report={props.progress_report}
      view={props.view}
      expanded={props.expanded}
      archive={props.archive}
      isCurrentUserSuperAdmin={props.is_current_user_superadmin}
    />
  );
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ThemeProvider theme={themeAttuned}>
        <Container {...props}></Container>
      </ThemeProvider>
    </Layout>
  );
};
